<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menuHocoos" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layout-mode="layoutMode" @layout-change="onLayoutChange" @change-theme="changeTheme" />
    <transition name="layout-mask">
      <div v-if="mobileMenuActive" class="layout-mask p-component-overlay" />
    </transition>
    <Toast />
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue'
import AppMenu from './AppMenu.vue'
import AppConfig from './AppConfig.vue'
import AppFooter from './AppFooter.vue'

export default {
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
  },
  emits: ['change-theme'],
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menuHocoos: [
        {
          label: 'Users',
          items: [
            {
              label: 'List',
              icon: 'pi pi-fw pi-users',
              to: '/users',
            },
            {
              label: 'Daily',
              icon: 'pi pi-fw pi-chart-bar',
              to: '/users/daily',
            },
            {
              label: 'Monthly',
              icon: 'pi pi-fw pi-chart-line',
              to: '/users/monthly',
            },
          ],
        },
        {
          label: 'Sites',
          items: [
            {
              label: 'List',
              icon: 'pi pi-fw pi-home',
              to: '/sites-new',
            },
            {
              label: 'Daily',
              icon: 'pi pi-fw pi-chart-bar',
              to: '/sites/daily',
            },
            {
              label: 'Monthly',
              icon: 'pi pi-fw pi-chart-line',
              to: '/sites/monthly',
            },
            {
              label: 'Wizard Progress',
              icon: 'pi pi-fw pi-step-forward',
              to: '/wizard-progresses',
            },
          ],
        },

        {
          label: 'Pages',
          items: [
            {
              label: 'List',
              icon: 'pi pi-fw pi-home',
              to: '/pages',
            },
          ],
        },

        {
          label: 'Emails',
          items: [
            {
              label: 'Templates',
              icon: 'pi pi-fw pi-envelope',
              to: '/email-templates',
            },
            {
              label: 'Test data',
              icon: 'pi pi-fw pi-database',
              to: '/email-demo-data-list',
            },
            {
              label: 'Email',
              icon: 'pi pi-fw pi-file-pdf',
              to: '/email-templates-logs',
            },
          ],
        },
        {
          label: 'Utils',
          items: [
            {
              label: 'Buckets',
              icon: 'pi pi-fw pi-folder',
              to: '/buckets',
            },
            {
              label: 'Settings',
              icon: 'pi pi-fw pi-cog',
              to: '/settings',
            },
            {
              label: 'Processed Records',
              icon: 'pi pi-fw pi-list',
              to: '/processed-records',
            },
            {
              label: 'Message Queueing',
              icon: 'pi pi-fw pi-refresh',
              to: '/message-queueing',
            },
            {
              label: 'Search Content',
              icon: 'pi pi-fw pi-search',
              to: '/search-content',
            }
          ],
        },
        {
          label: 'Files',
          items: [
            {
              label: 'Image',
              icon: 'pi pi-fw pi-images',
              to: '/images',
            },
            {
              label: 'Video',
              icon: 'pi pi-fw pi-video',
              to: '/videos',
            },
            {
              label: 'Document',
              icon: 'pi pi-fw pi-images',
              to: '/documents',
            },
            {
              label: 'Audio',
              icon: 'pi pi-fw pi-images',
              to: '/audios',
            }
          ],
        },
        {
          label: 'Log',
          items: [
            {
              label: 'Event',
              icon: 'pi pi-fw pi-calendar',
              to: '/event-logs?syncQueryParams=true',
            },
            {
              label: 'Entity',
              icon: 'pi pi-fw pi-sitemap',
              to: '/log-entities',
            },
            {
              label: 'Requests',
              icon: 'pi pi-fw pi-list',
              to: '/log-requests-new?syncQueryParams=true',
            },
            {
              label: 'User Ai',
              icon: 'pi pi-fw pi-prime',
              to: '/log-ai-user-history-list',
            },
          ],
        },
        {
          label: 'Localize',
          items: [
            {
              label: 'Category',
              icon: 'pi pi-fw pi-comment',
              to: '/category-lcz-config',
            },
            {
              label: 'Industry',
              icon: 'pi pi-fw pi-compass',
              to: '/industry-lcz-config',
            },
            {
              label: 'Group',
              icon: 'pi pi-fw pi-comments',
              to: '/group-lcz-config',
            },
            {
              label: 'Content Pack',
              icon: 'pi pi-fw pi-box',
              to: '/content-pack-lcz-config',
            },
            {
              label: 'Content Pack Rephrase',
              icon: 'pi pi-fw pi-folder-open',
              to: '/content-pack-rephrase-lcz-config',
            },
            {
              label: 'Def product',
              icon: 'pi pi-fw pi-book',
              to: '/def-product-lcz-config',
            },
            {
              label: 'Global',
              icon: 'pi pi-fw pi-globe',
              to: '/global-lcz-config',
            },
          ],
        },
        {
          label: 'Segments',
          items: [
            {
              label: 'Groups',
              icon: 'pi pi-fw pi-tags',
              to: '/groups',
            },
            {
              label: 'Test Site Rules',
              icon: 'pi pi-fw pi-filter',
              to: '/test-site-rules',
            },
            {
              label: 'Specifications',
              icon: 'pi pi-fw pi-inbox',
              to: '/specifications',
            },
          ],
        },
        {
          label: 'Old',
          items: [
            {
              label: 'List',
              icon: 'pi pi-fw pi-minus-circle',
              to: '/sites',
            },
            {
              label: 'Event',
              icon: 'pi pi-fw pi-minus-circle',
              to: '/event-logs',
            },
            {
              label: 'Request',
              icon: 'pi pi-fw pi-minus-circle',
              to: '/log-requests',
            },
          ]
        }
      ],
    }
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.layoutMode === 'overlay',
          'layout-static': this.layoutMode === 'static',
          'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
          'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
          'layout-mobile-sidebar-active': this.mobileMenuActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false,
        },
      ]
    },
    logo() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo.svg'
    },
  },
  watch: {
    $route() {
      this.menuActive = false
      this.$toast.removeAllGroups()
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, 'body-overflow-hidden')
    else this.removeClass(document.body, 'body-overflow-hidden')
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }

      this.menuClick = false
    },
    onMenuToggle() {
      this.menuClick = true

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true
          }

          this.overlayMenuActive = !this.overlayMenuActive
          this.mobileMenuActive = false
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive
      }

      event.preventDefault()
    },
    onSidebarClick() {
      this.menuClick = true
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false
        this.mobileMenuActive = false
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className)
      else element.className += ' ' + className
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className)
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    },
    isDesktop() {
      return window.innerWidth >= 992
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static') return !this.staticMenuInactive
        else if (this.layoutMode === 'overlay') return this.overlayMenuActive
      }

      return true
    },
    changeTheme(event) {
      this.$emit('change-theme', event)
    },
  },
}
</script>

<style lang="scss">
@import './App.scss';
</style>
